/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import cookies from '../common/storage/cookies';
import { getAnalyticsStorageData } from '../tracking/session/sessionTrackingHelpers';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const useIsKeeper = () => {
    const location = useLocation();
    const isKeeperDesignEnabled = useSelector(getFeatureFlag('is-keeper-partnership-enabled'));
    if (!isKeeperDesignEnabled) {
        return false;
    }
    const urlParams = QueryString.parse(location.search.slice(1));
    if (urlParams?.utm_source?.includes('keeper')) {
        return true;
    }

    let session = null;
    if (typeof window !== 'undefined' && window?.localStorage) {
        session = getAnalyticsStorageData();
        return session?.landingParams?.utm_source === 'keeper';
    }

    const keeperCookie = cookies.get('__attentive_utm_param_source');
    return keeperCookie === 'keeper';
};

export default useIsKeeper;
