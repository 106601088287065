/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getSiteWideBannerData = (data) => {
    const bannerDataBottom = [];
    const bannerDataTop = [];

    data.forEach((element) => {
        if (element.use_as_eyebrow) {
            element.reference.forEach((block) => {
                bannerDataTop.push(block);
            });
        } else {
            element.reference.forEach((block) => {
                bannerDataBottom.push(block);
            });
        }
    });

    return {
        bannerDataBottom, bannerDataTop,
    };
};

export default {};
