/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - domain name: brand['domain-name']
 * @param {string} locale -
 * @param {string} viewport -
 * @param {string} layout -
 */

const findHeader = (brand, locale, viewport, layout) => (
    gql`{ 
        findHeader(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "${locale}", viewport: "${viewport}", layout: "${layout}") {
            content
        }
        findContent(brand: "global",  environment: "${GRAPHQL_ENV}", contentType:"brandtabs") {
            content
        },
    }`
);

export default findHeader;
